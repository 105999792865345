const accountsStatus = {
  true: "Активирован",
  false: "Не активирован"
};

const userStatus = {
  true: "Не заблокирован",
  false: "Заблокирован"
};

const userAuth = {
  true: "Зарегистрирован",
  false: "Не зарегистрирован"
};
export default {
  accountsStatus,
  userStatus,
  userAuth
};

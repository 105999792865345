const accountsStatus = {
  true: "Активирован",
  false: "Не активирован"
};

const userStatus = {
  true: "Не заблокирован",
  false: "Заблокирован"
};

const userAuth = {
  true: "Зарегистрирован",
  false: "Не зарегистрирован"
};

const pushDeviceType = {
  FCM: "Android",
  WEB: "Web",
  APNS: "iOS"
};

export default {
  accountsStatus,
  userStatus,
  userAuth,
  pushDeviceType
};

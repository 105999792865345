const serviceTask = {
  add: "Добавление контроллера",
  repair: "Ремонт",
  admin: "Администратор",
  system: "Система",
  "in-process": "в работе",
  finished: "выполнена",
  closed: "закрыта",
  repeated: "Повторная",
  dismissed: "Отклонена"
};

export default serviceTask;

const controllersType = {
  door: "Дверь",
  wicket: "Калитка",
  "wing-gate": "Ворота распашные",
  "sliding-gate": "Ворота откатные",
  "lift-gate": "Шлагбаум",
  "porch-door": "Дверь подъездная",
  "inner-door": "Дверь внутренняя",
  "apartment-room": "Дверь квартирная",
  lift: "Лифт",
  mailbox: "Почтовый ящик",
  "garage-gate": "Гаражные ворота",
  turnstile: "Турникет"
};

export default controllersType;

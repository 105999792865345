<template>
  <v-menu
    @click="this.menu = true"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="range ? dateRangeText : formattingDate"
        :label="label"
        prepend-icon="mdi-calendar"
        clearable
        @click:clear="$emit('input', null)"
        readonly
        :error-messages="errors"
        :required="required"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :first-day-of-week="1"
      :weekday-format="getCustomDay"
      :disabled="disabled"
      color="#95c23d"
      :range="range"
      :show-current="false"
      v-model="selectedDate"
      @input="[closeMenu(), $emit('input', $event)]"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  data() {
    return {
      menu: false
    };
  },

  props: {
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    value: {
      type: [String, Array]
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean
    },
    showCurrent: {
      type: Boolean
    }
  },
  model: {
    prop: "value",
    event: "input"
  },

  computed: {
    getPreQuery() {
      return this.$store.getters.getPreQuery;
    },
    formattingDate() {
      return this.value ? moment(this.value).format("DD.MM.YYYY") : "";
    },
    selectedDate: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    dateRangeText: {
      get() {
        return Array.isArray(this.value)
          ? this.sorting(this.value)
              .map(el => moment(el).format("DD.MM.YYYY"))
              .join(" - ")
          : "";
      },
      set(val) {
        return val;
      }
    }
  },
  created() {
    this.$events.$on("clear_date-picker", () => {
      this.$emit("input", null);
    });
  },

  methods: {
    sorting(arr) {
      return arr.sort();
    },
    closeMenu() {
      if (this.range) {
        return;
      }
      this.menu = false;
    },
    getCustomDay(date) {
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-dialog width="95vw" style="overflow-y: hidden;" v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on" v-bind:init="init">
        <v-btn @click="init" v-on="on">
          <v-icon>
            mdi-full
          </v-icon>
        </v-btn>
      </slot>
    </template>

    <TableInPopUp @close="close">
      <slot></slot>
    </TableInPopUp>
  </v-dialog>
</template>

<script>
import TableInPopUp from "@/components/tables/TableInPopUp";
export default {
  name: "TablePopUp",
  components: { TableInPopUp },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    init() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>

<style>
.v-dialog {
  box-shadow: unset !important;
}
</style>

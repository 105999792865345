<template>
  <table class="table">
    <thead>
      <tr>
        <slot name="header">
          <th v-if="deleteButtonDirectionStart">Удаление</th>
          <th v-if="deleteAll" class="ma-0 pb-0 pt-0" style="min-width: 10px;">
            <v-checkbox
              value="all"
              v-model="checkedAll"
              @click="$emit('all_ckeck_click', { value: checkedAll })"
              hide-details
            ></v-checkbox>
          </th>
          <th v-if="restoreButton">Восстановление</th>
          <th
            v-for="(head, index) in header"
            :key="index"
            :style="head.width ? 'min-width: ' + head.width + 'px' : ''"
            :class="head.sort ? 'header-table' : ''"
            @click="
              head.sort ? $emit('sort_click', { value: head.value }) : null
            "
          >
            <div
              class="d-flex align-center justify-center"
              :class="
                head.value == currentArrowValue && head.sort
                  ? 'header-table--active'
                  : ''
              "
            >
              <img
                height="16"
                v-if="head.src"
                :src="head.src"
                :alt="head.value"
              />
              <slot v-else v-bind:head="head" :name="'header.' + head.value">
                {{ head.text }}
              </slot>
              <span
                v-if="head.sort"
                style="width: 10px"
                class="ml-1 header-table__arrow"
                :class="[
                  head.value == currentArrowValue && arrowDirectionDown
                    ? 'header-table__arrow--down'
                    : '',
                  head.value == currentArrowValue
                    ? ''
                    : 'header-table__arrow--none'
                ]"
              >
                <img src="/img/icons/up-arrow.svg" alt="up-arrow" />
              </span>
            </div>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, ind) in tableData"
        :key="ind"
        class="item"
        :class="{
          '--hover-none': disableRouter,
          'blocked-user': item.is_active == false
        }"
        @click="
          $emit('row_click', { id: item.id, item: item, target: $event.target })
        "
      >
        <slot name="delete-td-start" :itemChild="item"></slot>
        <slot name="delete-all" :itemChild="item"></slot>
        <slot name="restore-td-child" :item="item"></slot>
        <td
          v-for="(head, index) in header"
          :key="index"
          class="ym-hide-content"
        >
          <slot
            :name="'item.' + head.value"
            v-bind:item="item"
            v-bind:head="head"
          >
            <div
              style="background-color: inherit"
              class="d-flex flex-column align-center justify-center"
              v-if="head.renderFunction"
              v-html="head.renderFunction(item) || '-'"
            ></div>
            <div v-else-if="head.text === '№'">{{ ind + 1 }}</div>
            <div v-else>{{ item[head.value] || "-" }}</div>
          </slot>
        </td>
        <slot name="delete-td" :itemChild="item"></slot>
      </tr>
      <div
        class="d-flex align-center justify-center"
        style="text-align: center; width: 100%;"
        v-if="tableData.length === 0"
      >
        Нет данных
      </div>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "BasicTable",
  props: {
    header: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    deleteButtonDirectionStart: {
      type: Boolean,
      default: false
    },
    deleteAll: {
      type: Boolean,
      default: false
    },
    restoreButton: {
      type: Boolean
    },
    disableRouter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkedAll: {
      get() {
        return this.$store.getters.getCheckedAll;
      },
      set(value) {
        this.$store.commit("SET_CHECKED_ALL", value);
      }
    },
    arrowDirectionDown() {
      return !this.$store.getters.getSortType;
    },
    currentArrowValue() {
      return this.$store.getters.getSortValue;
    }
  }
};
</script>

<style scoped>
.header-table {
  cursor: pointer;
}

.header-table--active {
  font-weight: bold;
  color: #464646;
}

.blocked-user {
  background-color: rgb(245 211 211 / 50%) !important;
}

.header-table__arrow,
.header-table:hover .header-table__arrow--none {
  display: inline;
  cursor: pointer;
}

.header-table__arrow--none {
  display: none;
}

.header-table__arrow--down {
  transform: rotate(180deg);
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.item:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.--hover-none:hover {
  cursor: default;
}
</style>

import cities from "./cities";
import organizationsType from "./organizationsType";
import objectType from "./objectType";
import acquiringProvider from "./acquiringProvider";
import acquiringEntity from "./acquiringEntity";
import acquiringEntityPayment from "./acquiringEntityPayment";
import controllersType from "./controllersType";
import serviceTaskPriority from "./serviceTaskPriority";
import accountsStatus from "./accountsStatus";
import controllersStatus from "./controllersStatus";
import serviceTask from "./serviceTask";
import organizationOwnership from "./organizationOwnership";
import timezone from "./timezone";
import cityObject from "./cityObject";
import weekDay from "./weekDay";
import childrenVisitLog from "./childrenVisitLog";
import userTypes from "./userTypes";
export const dictionariesHelper = {
  weekDay,
  cityObject,
  timezone,
  organizationOwnership,
  cities,
  organizationsType,
  objectType,
  acquiringProvider,
  acquiringEntity,
  acquiringEntityPayment,
  controllersType,
  accountsStatus,
  serviceTaskPriority,
  controllersStatus,
  serviceTask,
  childrenVisitLog,
  userTypes
};

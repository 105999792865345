const weekDay = {
  fri: "ПТ",
  mon: "ПН",
  sat: "СБ",
  tue: "ВТ",
  thu: "ЧТ",
  sun: "ВС",
  wed: "СР"
};

export default weekDay;

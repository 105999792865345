const objectType = {
  other: "Другое",
  "business-center": "Офис, бизнес-центр",
  bank: "Банк",
  "educational-institution": "Образовательное учреждение",
  "industrial-enterprise": "Промышленное предприятие",
  "protected-zone": "Охраняемая зона",
  parking: "Автостоянка, парковка",
  "traffic-lane": "Место проезда автотранспорта",
  "housing-estate": "Многоквартирный дом, жилой комплекс",
  "community-office": "Общественное учреждение",
  "apartment-house": "Частный дом, коттедж",
  kindergarten: "Детский сад"
};

export default objectType;

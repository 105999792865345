const organizationOwnership = {
  ИП: "ИП",
  ПАО: "ПАО",
  НАО: "НАО",
  ООО: "ООО",
  НО: "Некоммерческое организация",
  МУ: "Мунципальное учреждение",
  MADOU: "МАДОУ",
  MKDOU: "МКДОУ",
  MBDOU: "МБДОУ",
  MBOU: "МБОУ",
  MAUD: "МАУД",
  MDOU: "МДОУ",
  MOU: "МОУ",
  MBU: "МБУ"
};
export default organizationOwnership;

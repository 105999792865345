const tableColsAccounts = [
  {
    text: "ID",
    value: "id",
    width: 25,
    sort: true
  },
  {
    text: "Номер л/с",
    value: "number"
    // sort: true
  },
  {
    text: "Статус",
    renderFunction: value =>
      "<span style='color: " +
      (value.activated ? "#95C23D !important" : "#EB5C6D !important") +
      "';>" +
      (value.activated ? "Активирован" : "Не активирован") +
      "</span>"
  },
  {
    text: "Пользователей/максимум",
    value: "max_users",
    renderFunction: value => {
      return (value?.users?.length || 0) + "/" + value.max_users;
    },
    sort: true
  },
  {
    text: "Пользователь",
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    sort: true,
    // value: "users"
    renderFunction: value =>
      "<div>" +
      value.users.reduce((string, user) => {
        string += `<p>${user.full_name}, ${user.phone_number}</p>`;
        return string;
      }, "") +
      "</div>"
  },

  {
    text: "Группа пользователей",
    // value: "group",
    sort: true,
    src: "/img/table_icons/tabbar__icon_8.svg",
    width: 20,
    renderFunction: value =>
      "<div>" +
      value.groups.reduce((string, group) => {
        string += "<p>" + group.title + "</p>";
        return string;
      }, "") +
      "</div>"
  },
  {
    text: "Объект",
    value: "object_name",
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20,
    sort: true
  },
  {
    text: "Компания",
    value: "organization_name",
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дилер",
    value: "diller_name",
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  }
];

export default tableColsAccounts;

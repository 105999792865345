const cityObject = {
  1: {
    url: "http://89.223.125.49/api/v1/cities/1/",
    id: 1,
    name: "Казань",
    region: 16,
    region_display: "Республика Татарстан (Татарстан)",
    timezone: 18
  },
  2: {
    url: "http://89.223.125.49/api/v1/cities/2/",
    id: 2,
    name: "Другой",
    region: 1,
    region_display: "Республика Адыгея (Адыгея)",
    timezone: 18
  },
  3: {
    url: "http://89.223.125.49/api/v1/cities/3/",
    id: 3,
    name: "Город 37",
    region: 1,
    region_display: "Республика Адыгея (Адыгея)",
    timezone: 18
  }
};

export default cityObject;

<template>
  <div class="d-flex">
    <div
      class="d-flex flex-column"
      style="width: calc(100% - 45px); height: 89vh;"
    >
      <div class="d-flex ">
        <button
          @click="scrollToLeft"
          class="list__btn-nav list__btn-nav_left"
          id="list__btn-nav_left"
        >
          <img src="img/icons/list_btn-nav_left.svg" alt="icon" />
        </button>
        <div ref="topScroll" class="top-scroll" @scroll.passive="handleScroll">
          <div
            :style="{
              width: `${scrollWidth}px`,
              height: '12px'
            }"
          />
        </div>
        <button
          @click="scrollToRight"
          class="list__btn-nav list__btn-nav_right"
          id="list__btn-nav_right"
        >
          <img src="img/icons/list__btn-nav_right.svg" alt="icon" />
        </button>
      </div>
      <div @scroll.passive="handleScroll" ref="content" class="card-box">
        <slot> </slot>
      </div>
    </div>
    <button
      @click="$emit('close')"
      class="btn btn-config btn-config_activate btn-minimize mt-8"
    >
      <img src="img/icons/btn-config_icon-4.svg" alt="icon" />
    </button>
  </div>
</template>

<script>
export default {
  name: "TableInPopUp",
  data() {
    return {
      scrollWidth: 0,
      scrolling: false,
      setWidth: false
    };
  },
  mounted() {
    if (this.$refs.content) {
      this.$nextTick(() => {
        this.scrollWidth = this.$refs.content.scrollWidth;
        this.setWidth = true;
      });
    }
  },
  updated() {
    if (this.$refs.content && (!this.setWidth || this.scrollWidth === 0)) {
      this.scrollWidth = this.$refs.content.scrollWidth;
      this.setWidth = true;
    }
  },
  methods: {
    scrollToLeft() {
      this.$refs.topScroll.scrollLeft -= 150;
    },
    scrollToRight() {
      this.$refs.topScroll.scrollLeft += 150;
    },
    handleScroll(event) {
      if (event.target._prevClass.includes("card-box")) {
        if (this.scrolling) {
          this.scrolling = false;
          return true;
        }
        this.scrolling = true;
        this.$refs.topScroll.scrollLeft = this.$refs.content.scrollLeft;
      } else {
        if (this.scrolling) {
          this.scrolling = false;
          return true;
        }
        this.scrolling = true;
        this.$refs.content.scrollLeft = this.$refs.topScroll.scrollLeft;
      }
    }
  }
};
</script>

<style>
.card-box {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
  overflow-y: scroll;
  height: 85vh;
  overflow-x: hidden;
}

.btn-minimize {
  width: 42px;
  height: 48px;
}

thead tr:first-child th {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 302;
}
th[scope="row"] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 301;
}

th {
  background-color: #fff !important;
}

.top-scroll {
  overflow-x: scroll;
}
</style>
